import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import BDSLanding from 'components/CustomLanding/BDSLanding';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function BDSLandingPage(props) {
    let {
        data: { contentfulCustomLandingPage },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulCustomLandingPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulCustomLandingPage, 'seoListing.seoTitle')}
                slug={'texture-guide'}
            />
            <BDSLanding page={contentfulCustomLandingPage} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulCustomLandingPage(
            node_locale: { eq: $locale }
            slug: { eq: "blow-dry-spray" }
        ) {
            ...ContentfulCustomLandingPageFields
            section1Data {
                fragments {
                    key
                    value
                    type
                }
            }
        }
    }
`;
