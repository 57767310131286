import React from 'react';
import get from 'lodash.get';
import HeroSlider from 'components/HeroSlider';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import url from 'helpers/url';
import Videos from 'blocks/Videos';
import { formatHTML } from 'helpers/text';
import {
    Spacing,
    SharedStyles,
    Container,
    VideoBlock,
    NewVideoWithDesc,
    GenericBenefitList,
    GenericProductList,
    ImageTextTile,
    ListOfImg,
    ImageGrid,
    VideoWithInfo,
} from '../../kenra-storybook/index'

const {
    StSectionInfo,
    StSectionTitle,
    StLargeSectionTitle,
    StSectionBody,
    StSectionLink,
    VidTitle,
    StTitle,
    StMediumTitle,
    StPageTitle,
    StSubTitle
} = SharedStyles;

function parseFragments(fragments, intl) {
    let fragmentDataMap = fragments.map(section => {
        let returnData = {};
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['img'] = fragmentData.value;
                    break;
                case 'Overlay':
                    returnData['overlay'] = fragmentData.value;
                    break;
                case 'Title':
                    returnData['title'] = fragmentData.value;
                    break;
                case 'Text':
                    returnData['text'] = fragmentData.value;
                    if (fragmentData.value !== null) {
                        returnData['text'] = fragmentData.value.replace(
                            '\\n',
                            '\n '
                        );
                    }
                    break;
                case 'ItemColor':
                    returnData['color'] = fragmentData.value;
                    break;
                case 'ProductLink':
                    if (fragmentData.value) {
                        returnData['link'] = '/product' + fragmentData.value;
                        returnData['linkText'] = intl.formatMessage({
                            id: 'product-view',
                        });
                    }
                    break;
                default:
                    break;
            }
        });
    
        return {
            image: returnData.img,
            overlay: returnData.overlay,
            title: returnData.title,
            text: returnData.text,
            itemColor: returnData.color,
            link: returnData.link,
            linkText: returnData.linkText,
        };
    });

    return fragmentDataMap;
}

const SAWLanding = ({ intl, page }) => {
    let section1Data = parseFragments(page.section1Data.fragments, null);
    let collectionData = parseFragments(page.collectionData.fragments, intl);
    let sec1Title =  get(page, 'section1Subtitle')
    let moreCollectionData = parseFragments(
        page.moreCollectionData.fragments,
        intl
    );
    let tutorials = page.tutorials;
    let youtubeLink = tutorials[0].youtubeLink;
    let videoThing = JSON.parse(get(page, 'comingSoonText.raw'))
    let videoThing2 = videoThing.content[0].content[0].value
    
    // let youTubeId = getYouTubeId(youtubeLink);
    
    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottom>
                <HeroSlider slides={page.banner} />
            </Spacing>
            <Spacing>
                <Container>
                    <StPageTitle style={{"margin-bottom":"12px"}}><h1>{get(page, 'title')}</h1></StPageTitle>
                    <StMediumTitle

                    style={{textTransform: 'unset', fontWeight:'normal', display:'flex', 'justifyContent':'center', 'align-items':'center'}}
                    
                    
                    dangerouslySetInnerHTML={{
                        __html: get(page, 'section1Subtitle'),
                    }}>
                        
                    </StMediumTitle>
                </Container>
            </Spacing>
            <Spacing>
                <Container>
                    {section1Data && (
                        <GenericBenefitList
                            items={section1Data}
                            title=''
                            embedTitleAndImage="true"
                            
                            bgImg={get(
                                page,
                                'section1Background.localFile.publicURL'
                            )}
                        />
                    )}
                </Container>
            </Spacing>
            <Spacing
                backgroundImage={get(
                    page,
                    'backgroundImage.localFile.publicURL'
                )}
            >
                <StSectionInfo>
                    <Container fullWidth>
                        <StTitle>
                            <h2>{get(page, 'collectionTitle')}</h2>
                        </StTitle>
                    </Container>
                </StSectionInfo>
                {collectionData && (
                    <div>
                        <GenericProductList
                            items={collectionData}
                            split5050
                            white
                            invertTextColor
                        />
                    </div>
                )}
            </Spacing>
            <Spacing>
                <section>
                    <Container useLargeWidth>
                        <StPageTitle style={{fontWeight:'500', textTransform:'uppercase', marginBottom:12}}><h1 style={{fontWeight:500}}>Behind the Scenes of our Chrome Shoot with David Lopez</h1></StPageTitle>
                        <div
                            style={{
                                paddingTop: 20,
                                paddingBottom: 20,
                                alignItems: 'center',
                                flexDirection: 'row',
                                flex: 1,
                                justifyItems: 'center',
                            }}
                            dangerouslySetInnerHTML={{ __html: videoThing2 }}
                        />
                        
                    </Container>
                </section>
            </Spacing>
            
        </>
    );
};

export default injectIntl(SAWLanding);
